import React from 'react'

import { useAuth } from '@azos/account'
import { makeAccountLayout } from '@main/factories/components/account'
import SignIn from '@presentation/pages/signIn'
import { useRouter } from 'next/router'

const LoginPage = () => {
  const { query } = useRouter()
  const { setFrom } = useAuth()

  React.useEffect(() => {
    if (query.origin) {
      setFrom(query.origin.toString())
    }
  }, [query, setFrom])

  return <SignIn />
}

LoginPage.getLayout = makeAccountLayout

export default LoginPage
