import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { AppProvider, AuthSuccessfulData } from '@azos/account'
import analytics from '@azos/analytics'
import { makeStorageProvider, useRemoteConfig } from '@azos/core'
import { env } from '@main/config/env'
import * as Sentry from '@sentry/nextjs'
import Router from 'next/router'

const AccountProvider: React.FCC = ({ children }) => {
  const remoteConfig = useRemoteConfig()
  const { addNotification } = useNotification()
  const storageProvider = makeStorageProvider()

  const handleAuthSuccessful = React.useCallback(
    async (data: AuthSuccessfulData) => {
      const from = data.from || '/seguro/beneficiarios'
      const isWebview = storageProvider.getJSON<string>('isWebview') === 'true'

      if (
        remoteConfig.get('B2C_UPSELL') &&
        data.user.insuredUser &&
        data.user.insuredUser?.info?.amountPolicies >= 1 &&
        !isWebview
      ) {
        window.location.replace(
          `${
            env.DASHBOARD_URL
          }/login?origin=/simular-novo-seguro&from=simulacao&token=${
            data.token
          }${isWebview ? '&webview=true' : ''}`,
        )
        return
      }

      await analytics.initializeLoggedUser(env.MIX_PANEL_ID, {
        email: data.user.email,
        displayName: data.user.displayName,
        isInsured: data.user.isInsured,
        isInsuredByBroker: data.user.isInsuredByBroker,
        isBroker: data.user.isBroker,
        brokerUser: data.user.brokerUser,
      })

      Sentry.configureScope(scope => {
        scope.setExtras({
          isInsured: data.user.isInsured,
          isInsuredByBroker: data.user.isInsuredByBroker,
          isBroker: data.user.isBroker,
        })
        scope.setUser({
          email: data.user.email,
          username: data.user.displayName,
        })
      })

      if (!data.user.isInsured && !Router.pathname.startsWith('/registro')) {
        await Router.push(`/registro?origin=${from}`)
        return
      }
      if (!Router.pathname.startsWith('/seguro')) {
        await Router.push(from)
        return
      }
    },
    [remoteConfig, storageProvider],
  )

  const handleAuthError = React.useCallback(
    (
      message = 'Falha ao se autenticar, verifique seus dados e tente novamente',
    ) => {
      addNotification?.({
        description: message,
        variant: 'error',
      })
    },
    [addNotification],
  )

  const handleAuthInfo = React.useCallback(
    (message = 'OK!') => {
      addNotification?.({
        description: message,
        variant: 'dark',
      })
    },
    [addNotification],
  )

  const handleSignOut = React.useCallback(() => {
    if (!Router.pathname.startsWith('/cadastro')) {
      const from = '/seguro/beneficiarios'
      Router.push(`/login?origin=${from}`)
    }
  }, [])

  return (
    <AppProvider
      onSuccessful={handleAuthSuccessful}
      onError={handleAuthError}
      onInfo={handleAuthInfo}
      onSignOut={handleSignOut}
    >
      {children}
    </AppProvider>
  )
}

export default AccountProvider
